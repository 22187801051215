export default [
  {
    value: null,
    text: 'None'
  },
  {
    value: 'Adrian',
    text: 'Adrian'
  },
  {
    value: 'Megan',
    text: 'Megan'
  },
  {
    value: 'Mike',
    text: 'Mike'
  },
  {
    value: 'Patrick',
    text: 'Patrick'
  },
  {
    value: 'Tomas',
    text: 'Tomas'
  },
  {
    value: 'Sean',
    text: 'Sean'
  },
  {
    value: 'George',
    text: 'George'
  },
  {
    value: 'Gerard',
    text: 'Gerard'
  }
];
